<template>
  <ListPageContainer :title="$t('announce.agencies_and_guides')">
    <r-row class="px-4" v-if="currentDataSet.length > 0 && !loading">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" :type="item.type" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading" :text="''"/>

    <ListLoading v-else/>
  </ListPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import NothingFound from '@/components/Miscellaneous/NothingFound';
import ListLoading from '@/components/Miscellaneous/ListLoading';
import LazyAnnouncement from '@/components/LazyAnnouncement/LazyAnnouncement';

export default {
  name: "AgenciesAndGuidesListView",

  components: {
    ListPageContainer,
    NothingFound,
    ListLoading,
    LazyAnnouncement
  },

  computed: {
    ...mapGetters(['GET_TRAVEL_AGENCIES', 'GET_GUIDES']),

    currentDataSet() {
      return [...this.GET_TRAVEL_AGENCIES, ...this.GET_GUIDES];
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    goToItem(item) {
      this.$router.push({ path: `/travel-agencies-and-guides/${item.id}` });
    },
  },
}
</script>
